"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FragmentSiteDetails = exports.FragmentSiteVariablesAttendee = exports.FragmentShowWithInstructions = exports.FragmentShow = exports.FragmentPage = exports.FragmentComponentFields = exports.FragmentCoreFields = exports.FragmentModuleFields = void 0;
const graphql_tag_1 = require("graphql-tag");
/**
 * Select fields from `Module` required for rendering it for an attendee.
 */
exports.FragmentModuleFields = (0, graphql_tag_1.gql) `
  fragment ModuleFields on Module {
    id
    name
    pageId
    pageIndex
    parentId
    parentSlotSlug
    parentSlotIndex
    coreId
    groupId
    path
    variables
    adminUI
  }
`;
/**
 * Select fields from `Core` required for rendering it for an attendee.
 */
exports.FragmentCoreFields = (0, graphql_tag_1.gql) `
  fragment CoreFields on Core {
    adminUI
    componentFieldData
    componentId
    createdAt
    fixedFlag
    id
    name
    coreType
    variables
  }
`;
/**
 * Select fields from `Component` required for rendering it for an attendee.
 */
exports.FragmentComponentFields = (0, graphql_tag_1.gql) `
  fragment ComponentFields on Component {
    id
    isRestricted
    name
    reactName
    slotConfiguration
    slug
    tags
    version
    description
    defaultFieldData
    createdAt
  }
`;
/**
 * Select fields from `Page` required for rendering either the page details or
 * the routing in the attendee experience.
 */
exports.FragmentPage = (0, graphql_tag_1.gql) `
  fragment PageFields on Page {
    id
    title
    name
    pathname
    siteId
    title
    variables
    adminUI
    privilege
    allModules {
      ...ModuleFields
    }
    allCores {
      ...CoreFields
    }
    allComponents {
      ...ComponentFields
    }
  }
  ${exports.FragmentModuleFields}
  ${exports.FragmentCoreFields}
  ${exports.FragmentComponentFields}
`;
/**
 * Select fields from `Show` required for reconstituting the show model.
 */
exports.FragmentShow = (0, graphql_tag_1.gql) `
  fragment ShowFields on Show {
    id
    name
    siteId
    isDefault
    createdAt
    updatedAt
  }
`;
/**
 * Select fields for `Show` to receive `showInstructions`.
 * @param $sinceInstructionId (optional) only get instructions after this
 * instruction
 */
exports.FragmentShowWithInstructions = (0, graphql_tag_1.gql) `
  fragment ShowWithInstructions on Show {
    id
    latestInstructionId(since: $sinceInstructionId)
    showInstructions(since: $sinceInstructionId) {
      id
      kind
      meta
    }
  }
`;
exports.FragmentSiteVariablesAttendee = (0, graphql_tag_1.gql) `
  fragment SiteVariablesAttendee on ISiteVariable {
    slug
    ... on SiteVariableColor {
      value
    }
    ... on SiteVariableDate {
      value
    }
    ... on SiteVariableImage {
      source: value {
        uri
      }
    }
    ... on SiteVariableLink {
      value
    }
    ... on SiteVariableNumber {
      numberValue: value
    }
    ... on SiteVariablePremiumVideo {
      source: value {
        uri
      }
    }
    ... on SiteVariableRichText {
      value
    }
    ... on SiteVariableSwitch {
      switchValue: value
    }
    ... on SiteVariableText {
      value
    }
    ... on SiteVariableVideo {
      source: value {
        uri
      }
    }
  }
`;
/**
 * Select fields from `Site` to render domain and flows and to be able to later
 * retrieve pages.
 */
exports.FragmentSiteDetails = (0, graphql_tag_1.gql) `
  fragment SiteDetails on Site {
    id
    name
    meta
    cssVariables
    css
    showControllerType
    variables {
      ...SiteVariablesAttendee
    }
    resources {
      __typename
      ... on LinkResource {
        href
        rel
        attributes
      }
      ... on ScriptResource {
        src
        type
      }
    }
    domains {
      id
      name
    }
    flows(take: 1) {
      id
      data
    }
    shows {
      id
    }
    pages {
      id
    }
  }
  ${exports.FragmentSiteVariablesAttendee}
`;
