"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.siteVariablesToCss = exports.slugToCssVariableName = void 0;
const assert_never_1 = require("./assert-never");
const cleanCssVariableName = (rawName) => {
    // CSS Variable names can use most of Unicode and emoji, but it's hard to
    // filter out the disallowed characters, so we are extra restrictive.
    return rawName.replace(/[^\w-]/g, '');
};
const slugToCssVariableName = (slug, prefix = '') => {
    while (prefix.length > 0 && !prefix.startsWith('--')) {
        prefix = `-${prefix}`;
    }
    return cleanCssVariableName(`${prefix}--${slug}`);
};
exports.slugToCssVariableName = slugToCssVariableName;
const siteVariablesToCss = (variables, 
/**
 * The prefix to denote where this variable is attached. Likely
 * site|domain|page|show
 */
prefix = '') => {
    const cssString = variables
        .map((x) => {
        const name = (0, exports.slugToCssVariableName)(x.slug, prefix);
        switch (x.kind) {
            case 'color':
                return `${name}: ${x.value};`;
            case 'image':
                return `${name}: url("${x.value.uri}");`;
            case 'link':
                return `${name}: url("${x.value}");`;
            case 'number':
                return `${name}: ${x.value};`;
            case 'rich_text':
                return `${name}: "${x.value}";`;
            case 'text':
                return `${name}: "${x.value}";`;
            case 'video':
                return `${name}: url("${x.value.uri}");`;
            case 'date': // intentional fallthrough
            case 'premium_video': // intentional fallthrough
            case 'show_variable': // intentional fallthrough
            case 'switch':
                return '';
            default:
                (0, assert_never_1.assertNever)(x);
        }
    })
        .join('\n');
    return `:root {${cssString}}`;
};
exports.siteVariablesToCss = siteVariablesToCss;
